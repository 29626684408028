import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { getStaticSEOImage, siteURL } from "../helpers";

function SEO({
  description,
  image,
  lang,
  meta,
  keywords,
  title,
  metaTags,
  metaKeywords,
  metaRobots,
  path,
}) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          author
          description
          title
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const customMetas = metaTags.map((metaTag) => metaTag.name) || [];
  const canonical = path ? `${site.siteMetadata.siteUrl}${path}` : null;

  let metas = [
    { name: "title", content: metaDescription },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `robots`,
      content:
        metaRobots ||
        "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
    },
    {
      property: `og:title`,
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: canonical,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      property: `twitter:url`,
      content:
        typeof window !== "undefined"
          ? window.location.href
          : process.env.GATSBY_VG_SITE_URL || "https://vackground.com",
    },
    {
      property: `twitter:title`,
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: `${title} | ${site.siteMetadata.title}`,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]
    .filter((meta) => !!meta)
    .concat(
      metaKeywords || keywords.length > 0
        ? {
            name: `keywords`,
            content: metaKeywords || keywords.join(`, `),
          }
        : []
    )
    .concat(meta)
    .concat(
      image
        ? [
            {
              name: `og:image`,
              content: `${siteURL}${image}`,
            },
            {
              name: `twitter:image`,
              content: `${siteURL}${image}`,
            },
          ]
        : []
    )
    .filter((singleMeta) => !customMetas.includes(singleMeta?.name))
    .concat(
      metaTags.map((metaTag) => ({
        name: metaTag?.name,
        content: metaTag?.content,
      }))
    );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={metas}
      title={title}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [
    "vackground",
    "graphics",
    "abstract",
    "pattern",
    "screen",
    "freebies",
    "gradient",
    "gradienta",
  ],
  meta: [],
  image: getStaticSEOImage(),
  metaTags: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  metaTags: PropTypes.array,
  metaKeywords: PropTypes.string,
  metaRobots: PropTypes.string,
  path: PropTypes.string,
};

export default SEO;
